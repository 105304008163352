import { useState } from "react"
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Chip, Typography } from "@material-ui/core"
import { Delete, ExpandMore, Edit } from "@material-ui/icons"
import SelectPlanDialog from "../SelectPlanDialog/SelectPlanDialog"
import Swal from "sweetalert2";

//redux
import { connect } from "react-redux";
import { cancelSubscription } from "../../../../stores/payment/actions";

function AccordionBlade (props) {
  const {
    site,
    plan,
    expanded = false,
    onChange = () => {}
  } = props

  const [selectPlanDialogOpen, setSelectPlanDialogOpen] = useState(false)
  const onSelect = async selected => {
    // Submit the linked plan here
    console.log(selected)
  }
  const handleCancelSubscription = () =>{
    Swal.fire({
      title: 'Are you sure want to unsubscribe?',
      text: 'Your license key for the associated website will be invalid after this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: "warning"
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await props.cancelSubscription(site.stripeSubscriptionId)
        Swal.fire('Subscription cancelled successfully', '', 'success')
      }
    })
  }
  return (
    <>
      <SelectPlanDialog
        open={selectPlanDialogOpen}
        selected={plan.id}
        site={site}
        onSelect={onSelect}
        onClose={() => { setSelectPlanDialogOpen(false) }}
      />
      <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{site.website}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography component="p" variant="h6">License Key</Typography>
            <Typography component="span" variant="body1">{site.licenseKey}</Typography>
          </div>
          {/* <div>
            <Typography component="p" variant="h6">Linked Plan</Typography>
            <Typography component="span" variant="body1">{plan.name}</Typography> <Chip size="small" label={`Plan ID ${plan.id}`} />
          </div> */}
        </AccordionDetails>
        <AccordionActions>
          <Button
            color="primary"
            startIcon={<Edit />}
            onClick={() => { setSelectPlanDialogOpen(true) }}
          >
            Change Website
          </Button>
          <Button 
            color="primary" 
            startIcon={<Delete />}
            onClick={handleCancelSubscription}
          >Delete</Button>
        </AccordionActions>
      </Accordion>
    </>
  )
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  cancelSubscription
};
export default connect(mapStateToProps, mapDispatchToProps)(AccordionBlade);