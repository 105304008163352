import { PAYMENT_LOADING, PAYMENT_SET_SUBSCRIPTIONS, PAYMENT_SET_INVOICES, PAYMENT_SET_PLANS } from "./constants";
const initialState = {
  loading: false,
  subscriptions: [],
  invoices: [],
  plans: [],
};
export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PAYMENT_SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case PAYMENT_SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case PAYMENT_SET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
}
