import React, {useState} from "react"
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  CircularProgress,
  Button
} from "@material-ui/core"
import { CloudDownload } from "@material-ui/icons"
import downloadURI from "../../../../helpers/download"
import Swal from "sweetalert2"

//redux
import { connect } from "react-redux";
import { downloadTheme } from "../../../../stores/licenses/actions";

function SiteTable ({ sites, downloadTheme }) {
  const [btnLoading, setBtnLoading] = useState(false)
  const handleDownload = async(website) =>{
    try{
      setBtnLoading(true)
      const resp = await downloadTheme({website})
      if(resp?.theme?.file){
        downloadURI(resp.theme.file, "theme.zip")
      }else{
        Swal.fire("Something went wrong!", "Please contact admin", "error")
      }
    }catch(err){
      let message = "Something went wrong!"
      if(err?.response?.data?.message){
        message = err?.response?.data?.message
      }
      Swal.fire(message, "", "error")
    }finally{
      setBtnLoading(false)
    }
  }
  return (
    <List>
      {sites.map((site) => (
        <ListItem key={site._id} button>
          <ListItemText primary={site.website}/>
          <ListItemText primary={site.licenseKey}/>
          <ListItemSecondaryAction>
            <Tooltip title="Download theme ZIP">
            <Button 
              variant="outlined" 
              startIcon={btnLoading ? <CircularProgress size={26} /> : <CloudDownload />}
              onClick={() => {
                // Download the zip
                handleDownload(site.website)
              }}
            >
              Download Theme
            </Button>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  downloadTheme
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteTable);