import AccordionBlade from "./AccordionBlade"
import mock from "../../../dashboard/mock"

export default function SiteAccordion (props) {
  const {
    sites,
    expandedId,
    onChangeExpanded = () => {}
  } = props

  const findAssociatedPlan = planId => (
    // Replace with actual plans from actual user
    // mock.plans.find(plan => plan.id === planId)
    mock.plans[0]
  )

  return (
    <>
      {sites.map(site => (
        <AccordionBlade
          key={site._id}
          onChange={() => {
            onChangeExpanded(site.website)
          }}
          expanded={expandedId === site.website}
          site={site}
          plan={findAssociatedPlan(site.planId)}
        />
      ))}
    </>
  )
}