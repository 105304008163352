import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

export default function DowngradeModal ({
  open,
  onConfirm = () => {},
  onClose = () => {}
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Need to disable your sites</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            The plan you select has fewer available sites than your active sites.
            To choose this plan, you need to disable some of your sites.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => { onConfirm() }}
        >
          Manage Sites
        </Button>
      </DialogActions>
    </Dialog>
  )
}