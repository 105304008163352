import React, {useEffect} from "react";
import SiteAccordion from "./components/SiteAccordion/SiteAccordion"
import PageTitle from "../../components/PageTitle/PageTitle";
import mock from "../dashboard/mock"
import { useState } from "react";

//redux
import { connect } from "react-redux";
import { fetchLicenses } from "../../stores/licenses/actions";

function Sites (props) {
  const { sites } = mock
  const [expandedId, setExpandedId] = useState()

  const changeExpandedAccordionBlade = clickedBlade => {
    if (expandedId !== clickedBlade) {
      setExpandedId(clickedBlade)
    } else {
      setExpandedId()
    }
  }
  useEffect(()=>{
    props.fetchLicenses()
  }, [])

  return (
    <>
      <PageTitle title="Your Sites" />
      <SiteAccordion sites={props.licenses} expandedId={expandedId} onChangeExpanded={changeExpandedAccordionBlade} />
    </>
  )
}
const mapStateToProps = (state) => ({
  licenses: state.licenses.licenses
});
const mapDispatchToProps = {
  fetchLicenses,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sites);