const mock = {
  user: {
    firstName: "John",
    lastName: "Smith",
    email: "john.smith@example.com"
  },
  plans: [{
    id: '0451',
    name: 'Basic Plan',
    numSites: 2,
    maxSites: 5
  }],
  billing: {
    nextBill: new Date(),
    price: 12345,
    paymentMethod: 'Paypal',
    paymentMethodNumber: 'xxxx xxxx 0451'
  },
  sites: [
    { id: 0, url: 'https://www.example.com', planId: '0451' },
    { id: 1, url: 'https://www.johnsmith.com', planId: '0451' }
  ],
  bills: [
    {
      id: '018234',
      date: new Date(),
      amount: 12345,
      notes: 'Payment for Basic Plan #0451'
    },
    {
      id: '013347',
      date: new Date(),
      amount: 12345,
      notes: 'Payment for Basic Plan #0451'
    }
  ],
  availablePlans: [
    {
      name: "First License",
      price: 99,
      maxSites: 1,
      interval: "yearly"
    }
    // ,
    // {
    //   name: "Additional Licences",
    //   price: 49,
    //   maxSites: Infinity,
    //   interval: "yearly"
    // },
    // {
    //   name: "First License",
    //   price: 249,
    //   maxSites: 1,
    //   interval: "lifetime"
    // },
    // {
    //   name: "Additional lifetime licences",
    //   price: 99,
    //   maxSites: Infinity,
    //   interval: "lifetime"
    // }
  ]
};

export default mock