import { LICENSES_LOADING, LICENSES_SET_DATA } from "./constants";
const initialState = {
  loading: false,
  licenses: [],
};
export function licenseReducer(state = initialState, action) {
  switch (action.type) {
    case LICENSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LICENSES_SET_DATA:
      return {
        ...state,
        licenses: action.payload,
      };
    default:
      return state;
  }
}
