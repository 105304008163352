import React, { useState } from "react";
import {
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	useMediaQuery,
	useTheme,
	IconButton,
	Tooltip,
	Switch,
	Typography,
	Button
} from "@material-ui/core";
import { ListRounded } from "@material-ui/icons"
import Swal from "sweetalert2"
import UserLicensesModal from "./UserLicensesModal";
import { Delete } from "@material-ui/icons"
//redux
import { connect } from "react-redux";
import { updateUser, deleteUser } from "../../../../stores/users/actions";

function UserTable({ users, updateUser, deleteUser }) {
	const [openModal, setOpenModal] = useState(false)
	const [selectedUser, setSelectedUser] = useState({})
	const useStyles = makeStyles(() => ({
		root: {
			tableHead: {
				fontWeight: 700
			}
		}
	}))
	const theme = useTheme()
	const match = useMediaQuery(theme.breakpoints.up('sm'))
	const onUpdateUser = async (id, checked) => {
		Swal.fire({
			title: 'Are you sure want to change the user status?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await updateUser({ activated: checked }, id)
			}
		})
	}
	const onUpdateLicense = async (id, checked) => {
		Swal.fire({
			title: 'Are you sure want to update free license?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await updateUser({ hasFreeLicense: checked }, id)
			}
		})
	}
	const handleDeleteUser = async (id) => {
		Swal.fire({
			title: 'Are you sure want to delete this user?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			icon: "warning"
		}).then(async (result) => {
			if (result.isConfirmed) {
				await deleteUser(id)
			}
		})
	}
	const classes = useStyles()
	const returnObject = (
		<>
			{match
				?
				(<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Account Activated</TableCell>
							<TableCell>Account Verified</TableCell>
							<TableCell>Check Licenses</TableCell>
							<TableCell>Activated</TableCell>
							<TableCell>Free License</TableCell>
							<TableCell>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map(user => (
							<TableRow key={user._id}>
								<TableCell>{user.name}</TableCell>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.activated ? "Yes" : "No"}</TableCell>
								<TableCell>{user.verified ? "Yes" : "No"}</TableCell>
								<TableCell>
									<IconButton onClick={() => {
										setSelectedUser(user)
										setOpenModal(true)
									}}>
										<ListRounded />
									</IconButton>
								</TableCell>
								<TableCell>
									<Tooltip title="Update Activated Status">
										<span>
											<Switch
												checked={user.activated}
												onChange={(e) => onUpdateUser(user._id, e.target.checked)}
											/>
										</span>
									</Tooltip>
								</TableCell>
								<TableCell>
									<Tooltip title="Update Free License">
										<span>
											<Switch
												checked={user.hasFreeLicense}
												onChange={(e) => onUpdateLicense(user._id, e.target.checked)}
											/>
										</span>
									</Tooltip>
								</TableCell>
								<TableCell>
									<div style={{ cursor: "pointer" }} onClick={() => handleDeleteUser(user._id)}>
										<Delete />
									</div>
								</TableCell>
							</TableRow>)
						)}
						{users.length === 0 && (
							<TableRow>
								<TableCell>No users found!</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>)
				:
				(<List>
					{users.map(user => (
						<>
							<ListItem key={user._id}>
								<ListItemText
									primary={user.name}
									secondary={
										<React.Fragment>
											<Typography
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												<b>Email:</b> {user.email}
											</Typography>
											<br />
											<Typography
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												<b>Activated:</b> {user.activated ? "Yes" : "No"}
											</Typography>
											<br />
											<Typography
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												<b>Verified:</b> {user.verified ? "Yes" : "No"}
											</Typography>
											<br />
											<Button
												onClick={() => {
													setSelectedUser(user)
													setOpenModal(true)
												}}
												type="button"
												variant="outlined"
												color="primary"
											>
												Check Licenses
											</Button>
											<br />
											<Typography
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												<b>Update Activated Status:</b> <Tooltip title="Update Activated Status">
													<span>
														<Switch
															checked={user.activated}
															onChange={(e) => onUpdateUser(user._id, e.target.checked)}
														/>
													</span>
												</Tooltip>
											</Typography>
											<br />
											<Typography
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												<b>Update Free License:</b> <Tooltip title="Update Free License">
													<span>
														<Switch
															checked={user.hasFreeLicense}
															onChange={(e) => onUpdateLicense(user._id, e.target.checked)}
														/>
													</span>
												</Tooltip>
											</Typography>
											<br />
											<Button
												onClick={() => handleDeleteUser(user._id)}
												type="button"
												variant="outlined"
												color="secondary"
											>
												<Delete /> Delete User
											</Button>
										</React.Fragment>
									}
								/>
							</ListItem>
							<hr />
						</>
					))}
				</List>)}
			<UserLicensesModal
				open={openModal}
				onClose={() => {
					setOpenModal(false)
					setSelectedUser({})
				}}
				user={selectedUser}
			/>
		</>
	)
	return returnObject
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	updateUser,
	deleteUser
};
export default connect(mapStateToProps, mapDispatchToProps)(UserTable);