import { Button, Grid } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import mock from '../dashboard/mock'
import EditableText from './components/EditableText'
import Swal from "sweetalert2"

//redux
import { connect } from "react-redux";
import { editProfile } from "../../stores/profile/actions";

function Profile (props) {
  const [user, setUser] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const submitProfile = async () => {
    // Submit here
    return
  }

  useEffect(() => {
    if (props.profile?._id) {
      setUser(props.profile)
      setIsFetching(false)
    }
  }, [props.profile])

  const onStopEditing = async () => {
    setIsSubmitting(true)
    try {
      const formData = {
        email: user.email,
        name: user.name
      }
      await props.editProfile(formData, user._id)
      Swal.fire("Profile updated successfully!", "", "success")
    } catch (e) {
      Swal.fire("Something went wrong", "", "error")
      console.error(e)
    } finally {
      setIsSubmitting(false)
      setIsEditing(false)
    }
  }

  return (
    <>
      <PageTitle title="Profile" />
      <div style={{
        marginBottom: '2em'
      }}>
        {!isEditing ?
          <Button color="primary" startIcon={<Edit />} variant="contained" onClick={ () => { setIsEditing(true) }}>Edit Profile</Button> :
          <Button color="primary" startIcon={<Check />} variant="contained" onClick={ () => { onStopEditing() }} disabled={isSubmitting}>
            Done Editing
          </Button>
        }
      </div>
      <Grid container spacing={1}>
        <Grid xs={12} sm={6} item>
          <EditableText
            label="Full Name"
            value={user.name}
            isEditing={isEditing}
            onChange={(value)=>setUser({...user, name: value})}
            type="text"
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <EditableText
            label="E-mail"
            value={user.email}
            isEditing={isEditing}
            onChange={(value)=>setUser({...user, email: value})}
            type="text"
          />
        </Grid>
      </Grid>
    </>
  )
}
const mapStateToProps = (state) => ({
  profile: state.profile.profile
});
const mapDispatchToProps = {
  editProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);