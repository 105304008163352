import React, { useEffect, useState } from "react";
import { Dialog, Box, Typography, TextField } from "@material-ui/core";
import useStyles from "../login/styles";

//stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SplitForm from "./StripeForm/SplitForm";

//redux
import { connect } from "react-redux";
import { fetchPlans, createSubscription, createCharge } from "../../stores/payment/actions";

const NewSubscriptionModal = (props) => {
	var classes = useStyles();
	const style = {
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #ccc',
		boxShadow: 24,
		p: 4,
		marginBottom: 20
	};
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ENV === "PRODUCTION" ? process.env.REACT_APP_STRIPE_KEY_PROD : process.env.REACT_APP_STRIPE_KEY);
	const [state, setState] = useState({
		plans: [],
		priceId: "",
		paymentStatus: {},
		website: ""
	});
	const [loading, setLoading] = useState(false)

	const handleCreateSubscription = async (cardInfo) => {
		try {
			setLoading(true)
			const result = await props.createSubscription({
				paymentMethodId: cardInfo.paymentMethodId,
				priceId: cardInfo.priceId,
				token: cardInfo.token,
			});
			const { client_secret, status, error, subscription } = result;
			setState({ ...state, paymentStatus: { client_secret, status, error, subscription } });
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false)
		}
	};
	const handleCreateCharge = async (cardInfo) => {
		try {
			setLoading(true)
			const result = await props.createCharge({
				paymentMethodId: cardInfo.paymentMethodId,
				token: cardInfo.token,
			});
			const { client_secret, status, id } = result.paymentIntentConfirm;
			setState({ ...state, paymentStatus: { client_secret, status, payment_intent_id: id } });
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false)
		}
	};
	useEffect(() => {
		props.fetchPlans()
	}, [])
	useEffect(() => {
		if (props.plans?.length) {
			if (Array.isArray(props.plans[0]?.plans) && props.plans[0]?.plans?.length) {
				const priceId = props.plans[0]?.plans[0]?.id
				setState({ ...state, priceId, plans: props.plans })
			}
		}
	}, [props.plans])
	return (
		<Box sx={style}>
			{state.priceId === "" ? (
				<Typography variant="subtitle1" component="p">Initiating payment form...</Typography>
			) : (
				<>
					{props.isLifetimePurchase ? (
						<Typography variant="subtitle2" component="b">
							Affiliate Fish Theme Lifetime License ($249)
						</Typography>
					) : (
						<Typography variant="subtitle2" component="b">
							{state.plans[0]?.name} ({state.plans[0]?.plans[0]?.amount}/{state.plans[0]?.plans[0]?.interval})
						</Typography>
					)}

					<hr style={{ marginBottom: 10 }} />
					<TextField
						id="website"
						InputProps={{
							classes: {
								underline: classes.textFieldUnderline,
								input: classes.textField,
							},
						}}
						value={state.website}
						onChange={e => setState({ ...state, website: e.target.value })}
						margin="normal"
						placeholder="Enter Domain/Subdomain"
						type="text"
						fullWidth
						required
					/>
					<Elements stripe={stripePromise}>
						<SplitForm
							priceId={state.priceId}
							handleClick={(cardInfo)=>{
								if(props.isLifetimePurchase){
									handleCreateCharge(cardInfo)
								}else{
									handleCreateSubscription(cardInfo)
								}
							}}
							loading={loading}
							disabled={state.priceId === ""}
							paymentStatus={state.paymentStatus}
							close={props.close}
							website={state.website}
							isLifetimePurchase={props.isLifetimePurchase}
						/>
					</Elements>
				</>
			)}
		</Box>
	)
}
const mapStateToProps = (state) => ({
	plans: state.payment.plans
});
const mapDispatchToProps = {
	fetchPlans,
	createSubscription,
	createCharge
};
export default connect(mapStateToProps, mapDispatchToProps)(NewSubscriptionModal);