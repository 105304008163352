import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import Users from "../../pages/admin/users/Users";
import Theme from "../../pages/admin/theme/Theme";
import AdminProfile from "../../pages/admin/profile/AdminProfile";

// context
import { useLayoutState } from "../../context/LayoutContext";

function LayoutAdmin(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/admin/users" component={Users} />
              <Route path="/admin/theme" component={Theme} />
              <Route path="/admin/profile" component={AdminProfile} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(LayoutAdmin);
