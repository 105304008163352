import React, { useEffect } from "react";
import { Grid } from "@material-ui/core"
import { useState } from "react"
import PageTitle from "../../components/PageTitle/PageTitle"
import mock from "../dashboard/mock"
import DowngradeModal from "./DowngradeModal/DowngradeModal"
import PlanCard from "./PlanCard"
import LifetimeLicenseCard from "./LifetimeLicenseCard"
import { Typography } from "../../components/Wrappers";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import NewSubscriptionModal from "./NewSubscriptionModal";

//redux
import { connect } from "react-redux";
import { fetchSubscriptions } from "../../stores/payment/actions";
import { fetchLicenses } from "../../stores/licenses/actions";

function Subscriptions(props) {
  const {
    availablePlans,
    sites
  } = mock
  const currentPlan = mock.plans[0]

  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false)
  const [newSubscriptionModalOpen, setNewSubscriptionModalOpen] = useState(false)
  const [isLifetimePurchase, setLifetimePurchase] = useState(false)

  const onChoose = chosen => {
    if (chosen.maxSites < sites.length) {
      setDowngradeModalOpen(true)
    } else {

    }
  }
  useEffect(() => {
    props.fetchSubscriptions()
    props.fetchLicenses()
  }, [])

  const lifetimeLicenses = props.licenses.filter(plan => plan.isLifetimePurchase)
  return (
    <>
      <DowngradeModal
        open={downgradeModalOpen}
        onClose={() => { setDowngradeModalOpen(false) }}
        onConfirm={() => { }}
      />
      <PageTitle title="Manage Subscriptions" />
      <ResponsiveButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginBottom: 20 }}
        onClick={()=>{
          setLifetimePurchase(false)
          setNewSubscriptionModalOpen(true)
        }}
      >Create New Subscription</ResponsiveButton>
      <ResponsiveButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginBottom: 20, marginLeft: 10 }}
        onClick={()=>{
          setLifetimePurchase(true)
          setNewSubscriptionModalOpen(true)
        }}
      >
        Purchase Lifetime License
      </ResponsiveButton>
      {newSubscriptionModalOpen && (
      <NewSubscriptionModal
        open={newSubscriptionModalOpen}
        close={() => setNewSubscriptionModalOpen(false)}
        isLifetimePurchase={isLifetimePurchase}
      />
      )}
      <Grid container spacing={1}>
        {props.subscriptions.map(plan => (
          <PlanCard
            plan={plan}
            active={currentPlan.name === plan.name}
            onChoose={onChoose}
            key={plan.name}
          />
        ))}
        {lifetimeLicenses.map(plan => (
          <LifetimeLicenseCard
            plan={plan}
            key={plan.name}
          />
        ))}
        {(props.subscriptions.length === 0 && lifetimeLicenses.length === 0) && (
          <Typography variant="h3" component="p" style={{ marginBottom: 20 }}>You don't have any active subscriptions!</Typography>
        )}
      </Grid>
      
    </>
  )
}
const mapStateToProps = (state) => ({
  subscriptions: state.payment.subscriptions,
  licenses: state.licenses.licenses
});
const mapDispatchToProps = {
  fetchSubscriptions,
  fetchLicenses
};
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);