import axios from "axios";
export function _uploadTheme(formData, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}theme`, formData, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _activeTheme(formData, token) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_API_URL}theme/${formData._id}`, formData, {
          headers:{
            Authorization: `Bearer ${token}`
          }
        })
        .then(async (response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }