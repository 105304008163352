import React from "react"
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  IconButton,
  Button,
  Typography
} from "@material-ui/core";
import { formatCurrency, formatDate } from "../../../helpers/formatters";
import { CloudDownload } from "@material-ui/icons"

//helpers
import downloadURI from "../../../helpers/download";

export default function BillingTable({ bills }) {
  const useStyles = makeStyles(() => ({
    root: {
      tableHead: {
        fontWeight: 700
      }
    }
  }))
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.up('sm'))

  const classes = useStyles()
  const returnObject = (
    match
      ?
      (<Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Billing ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>PDF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.map(bill => (
            <TableRow key={bill.id}>
              <TableCell>{bill.id}</TableCell>
              <TableCell>{formatDate(bill.date)}</TableCell>
              <TableCell>{formatCurrency(bill.total / 100)}</TableCell>
              <TableCell>
                <IconButton onClick={() => {
                  downloadURI(bill.invoice_pdf, `${bill.id}.pdf`)
                }}>
                  <CloudDownload />
                </IconButton>
              </TableCell>
            </TableRow>)
          )}
          {bills.length === 0 && (
            <TableRow>
              <TableCell>No billing history found!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>)
      :
      (<List>
        {bills.map(bill => (
          <ListItem key={bill.id}>
            <ListItemText
              primary={`Billing ID: ${bill.id}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b>Date:</b> {formatDate(bill.date)}
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <b>Amount:</b> {formatCurrency(bill.total / 100)}
                  </Typography>
                  <br />
                  <Button
                    onClick={() => {
                      downloadURI(bill.invoice_pdf, `${bill.id}.pdf`)
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                  >
                    Download Invoice
                  </Button>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>)
  )

  return returnObject
}