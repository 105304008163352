import React, { useEffect } from "react";
import {
  Card,
  Container,
  Typography,
  Grid,
  makeStyles,
  Button
} from "@material-ui/core"
import { formatCurrency } from "../../helpers/formatters"
import moment from "moment";
import Swal from "sweetalert2";

//redux
import { connect } from "react-redux";
import { fetchPlans, cancelSubscription } from "../../stores/payment/actions";

function PlanCard ({ plan, active, plans, fetchPlans, cancelSubscription, onChoose = () => {} }) {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: '1.2em',
      textAlign: 'center',
      height: '325px',
    },
    features: {
      listStyleType: 'none',
      margin: 0,
      paddingInlineStart: 0,
      '& li': {
        textAlign: 'center',
        margin: 0,
        padding: 0
      }
    },
    planName: {
      fontWeight: 700,
      margin: '0.5em 0 1em 0'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%'
    }
  }))

  const classes = useStyles()
  const fetchSubscribedPlan = (subsription) => {
    const plan = plans.find((product) => product.plans[0].id === subsription.plan?.id)
    if (plan) {
      return plan
    }
  }
  const handleCancelSubscription = (plan) =>{
    Swal.fire({
      title: 'Are you sure want to unsubscribe?',
      text: 'Your license key for the associated website will be invalid after this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: "warning"
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await cancelSubscription(plan.id)
        Swal.fire('Subscription cancelled successfully', '', 'success')
      }
    })
  }
  useEffect(() => {
    fetchPlans()
  }, [])
  return (
    <Grid xs={12} sm={6} item>
      <Card className={classes.root}>
        <Container className={classes.container}>
          <div>
            <Typography variant="h3" className={classes.planName}>
              {fetchSubscribedPlan(plan)?.name}
            </Typography>
            <Typography variant="body1">{formatCurrency(plan.plan?.amount/100)}/{plan.plan?.interval_count} {plan.plan?.interval} for 1 website</Typography>
            <Typography variant="body1"><b>Subscription ID:</b> {plan.id}</Typography>
            <Typography variant="body1"><b>Next Payment:</b> {moment.unix(plan.current_period_end).format("MMMM Do YYYY")}</Typography>
          </div>
          <div className={classes.subscribeButton}>
            <Button
              variant="contained"
              size="small"
              disabled={active}
              onClick={() => handleCancelSubscription(plan)}
            >
              Unsubscribe
            </Button>
          </div>
        </Container>
      </Card>
    </Grid>
  )
}
const mapStateToProps = (state) => ({
  plans: state.payment.plans,
});
const mapDispatchToProps = {
  fetchPlans,
  cancelSubscription
};
export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);