import React, { useCallback, useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useDropzone } from 'react-dropzone'
import { TextField, Grid, Button } from "@material-ui/core";
import Swal from "sweetalert2"

//redux
import { connect } from "react-redux";
import { _uploadTheme, _activeTheme } from "../../../stores/theme/services";

function Theme(props) {
  const [state, setState] = useState({
    version: "",
    url: ""
  })
  const [file, setFile] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles)
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const handleUploadNewTheme = async () => {
    try {
      setBtnLoading(true)
      let formData = new FormData()
      formData.append("version", state.version)
      formData.append("url", state.url)
      formData.append("file", file)
      const resp = await _uploadTheme(formData, props.token)
      await _activeTheme({ ...resp.theme, active: true }, props.token)
      Swal.fire("New theme uploaded successfully!", "", "success")
      setState({
        version: "",
        url: ""
      })
      setFile(null)
    } catch (e) {
      let message = "Something went wrong"
      if (e?.response?.data?.message) {
        message = e?.response?.data?.message
      }
      Swal.fire(message, "", "error")
    } finally {
      setBtnLoading(false)
    }
  }
  return (
    <>
      <PageTitle title="Upload Theme" />
      <Grid container spacing={1} style={{ marginBottom: "2rem" }}>
        <Grid xs={12} sm={12} item>
          <TextField
            label={"Enter Theme Version"}
            type={"text"}
            style={{ width: "100%" }}
            value={state.version}
            onChange={(e) => setState({ ...state, version: e.target.value })}
          />
        </Grid>
        <Grid xs={12} sm={12} item>
          <TextField
            label={"Enter URL"}
            style={{ width: "100%" }}
            type={"text"}
            value={state.url}
            onChange={(e) => setState({ ...state, url: e.target.value })}
          />
        </Grid>
      </Grid>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p style={{ height: "10rem", border: "2px dashed #693bc5", textAlign: "center", color: "#693bc5" }}>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleUploadNewTheme}
        disabled={btnLoading}
      >
        Submit
      </Button>
    </>
  )
}
const mapStateToProps = (state) => ({
  token: state.auth.token
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Theme);