import React, { useEffect } from "react";
import {
  Card,
  Container,
  Typography,
  Grid,
  makeStyles,
  Button
} from "@material-ui/core"

//redux
import { connect } from "react-redux";

function LifetimeLicenseCard ({ plan }) {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: '1.2em',
      textAlign: 'center',
      height: '325px',
    },
    features: {
      listStyleType: 'none',
      margin: 0,
      paddingInlineStart: 0,
      '& li': {
        textAlign: 'center',
        margin: 0,
        padding: 0
      }
    },
    planName: {
      fontWeight: 700,
      margin: '0.5em 0 1em 0'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%'
    }
  }))
  const classes = useStyles()
  return (
    <Grid xs={12} sm={6} item>
      <Card className={classes.root}>
        <Container className={classes.container}>
          <div>
            <Typography variant="h3" className={classes.planName}>
              Affiliate Fish Theme Lifetime License
            </Typography>
            <Typography variant="body1">$249 for lifetime for 1 website</Typography>
            <Typography variant="body1">Website: {plan.website}</Typography>
          </div>
          <div className={classes.subscribeButton}>
            <Button
              variant="contained"
              size="small"
              disabled={true}
            >
              Lifetime License
            </Button>
          </div>
        </Container>
      </Card>
    </Grid>
  )
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LifetimeLicenseCard);