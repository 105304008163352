import { PAYMENT_LOADING, PAYMENT_SET_SUBSCRIPTIONS, PAYMENT_SET_INVOICES, PAYMENT_SET_PLANS } from "./constants";
import { _fetchInvoices, _fetchSubscriptions, _fetchPlans, _createSubscription, _cancelSubscription, _createCharge} from "./services";
import {fetchLicenses} from "../licenses/actions";

export function paymentsSetLoading(loading) {
	return {
		type: PAYMENT_LOADING,
		payload: loading
	};
}
export function paymentsSetSubscriptions(params) {
	return {
		type: PAYMENT_SET_SUBSCRIPTIONS,
		payload: params
	};
}
export function paymentsSetInvoices(params) {
	return {
		type: PAYMENT_SET_INVOICES,
		payload: params
	};
}
export function paymentsSetPlans(params) {
	return {
		type: PAYMENT_SET_PLANS,
		payload: params
	};
}
export const fetchInvoices = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_fetchInvoices(token).then((resp) => {
			if(Array.isArray(resp.data)){
				dispatch(paymentsSetInvoices(resp.data));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};
export const fetchSubscriptions = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_fetchSubscriptions(token).then((resp) => {
			if(Array.isArray(resp.data)){
				dispatch(paymentsSetSubscriptions(resp.data));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};
export const createSubscription = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_createSubscription(formData, token).then((resp) => {
			dispatch(fetchSubscriptions())
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};
export const cancelSubscription = (subscriptionId) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_cancelSubscription(subscriptionId, token).then((resp) => {
			dispatch(fetchSubscriptions())
			dispatch(fetchLicenses())
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};
export const fetchPlans = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_fetchPlans(token).then((resp) => {
			if(Array.isArray(resp)){
				dispatch(paymentsSetPlans(resp));
			}
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};
export const createCharge = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth
		dispatch(paymentsSetLoading(true));
		_createCharge(formData, token).then((resp) => {
			// dispatch(fetchSubscriptions())
			resolve(resp)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			dispatch(paymentsSetLoading(false));
		})
	})
};