import { Button, IconButton, useTheme } from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"

export default function ResponsiveButton (props) {
  const {
    onClick = () => {},
    icon,
    children
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  
  return (
    <>
      {!isMobile ?
        <Button {...props} onClick={onClick}>{children}</Button> :
        <IconButton {...props} onClick={onClick}>{icon}</IconButton>
      }
    </>
  )
}