import React, { useMemo, useState, useEffect } from "react";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import useResponsiveFontSize from "./useResponsiveFontSize";
import ResponsiveButton from "../../../components/ResponsiveButton/ResponsiveButton";
import { useHistory } from "react-router-dom";
import moment from "moment";

//redux
import { connect } from "react-redux";
import { createLicense } from "../../../stores/licenses/actions";

const isValidDomain = require('is-valid-domain')
const useOptions = () => {
	const fontSize = useResponsiveFontSize();
	const options = useMemo(
		() => ({
			style: {
				base: {
					fontSize,
					color: "#424770",
					letterSpacing: "0.025em",
					fontFamily: "Source Code Pro, monospace",
					"::placeholder": {
						color: "#aab7c4",
					},
				},
				invalid: {
					color: "#9e2146",
				},
			},
		}),
		[fontSize]
	);

	return options;
};

const SplitForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();
	const options = useOptions();
	const history = useHistory();

	useEffect(() => {
		if (props.paymentStatus.hasOwnProperty("status")) {
			handlePaymentVerify();
		}
	}, [props.paymentStatus]);
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!isValidDomain(props.website)) {
			Swal.fire("Please enter a valid domain!", "Don't include http:// or https:// in the domain name", "warning")
			return;
		}

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		let cardElement = elements.getElement(CardNumberElement)
		const payload = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
		});
		// console.log("[PaymentMethod]", payload);
		const token = await stripe.createToken(cardElement)
		// console.log("[Token]", token.token.id); 
		if (payload.hasOwnProperty("error")) {
			Swal.fire({
				title: payload.error.message,
				text: ``,
				icon: "error",
			});
		} else {
			props.handleClick({
				paymentMethodId: payload.paymentMethod.id,
				priceId: props.priceId,
				token: token.token.id
			});
		}
	};
	const handlePaymentVerify = async () => {
		const { client_secret, status, error, subscription, payment_intent_id } = props.paymentStatus;
		if (error) {
			//handling error if any
			Swal.fire({
				title: error.message,
				text: ``,
				icon: "error",
			});
			return;
		}
		if (status === "requires_action") {
			stripe.confirmCardPayment(client_secret).then(async function (result) {
				if (result.error) {
					console.log(result.error);
					Swal.fire({
						title: result.error.message,
						text: ``,
						icon: "error",
					});
				} else {
					await props.createLicense({
						website: props.website,
						expiry: moment.unix(subscription?.current_period_end).format("MM/DD/YYYY"),
						stripeSubscriptionId: subscription?.id || `INVALID_${Date.now()}`,
						stripePaymentIntentId: props.isLifetimePurchase ? result.id : `INVALID_${Date.now()}`,
						isLifetimePurchase: props.isLifetimePurchase
					})
					// Show a success message to your customer
					Swal.fire({
						title: `Your subscription has been created successfully!`,
						text: `You can now use the license key to activate the theme.`,
						icon: "success",
					}).then(() => {
						window.rewardful('convert', { email: props.profile.email })
						props.close();
					});
				}
			});
		} else {
			// console.log("You got the money!", result);
			// No additional information was needed
			// Show a success message to your customer
			await props.createLicense({
				website: props.website,
				expiry: moment.unix(subscription?.current_period_end).format("MM/DD/YYYY"),
				stripeSubscriptionId: subscription?.id,
				stripePaymentIntentId: props.isLifetimePurchase ? payment_intent_id : null,
				isLifetimePurchase: props.isLifetimePurchase
			})

			Swal.fire({
				title: `Your subscription has been created successfully!`,
				text: `You can now use the license key to activate the theme.`,
				icon: "success",
			}).then(() => {
				window.rewardful('convert', { email: props.profile.email })
				props.close();
			});
		}
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			{!props.disabled && (
				<div className="px-4 py-3">
					<div style={{ marginBottom: 15 }}>
						Card number
						<CardNumberElement options={options} />
					</div>
					<div style={{ marginBottom: 15 }}>
						Expiration date
						<CardExpiryElement options={options} />
					</div>
					<div style={{ marginBottom: 15 }}>
						CVC
						<CardCvcElement options={options} />
					</div>
				</div>
			)}
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<ResponsiveButton
					variant="contained"
					size="small"
					style={{ margin: 10 }}
					onClick={() => props.close()}
				>Close</ResponsiveButton>
				<ResponsiveButton
					variant="contained"
					size="small"
					color="primary"
					style={{ margin: 10 }}
					type="submit"
					disabled={!stripe || props.disabled || !props.website || props.loading}
				>Submit</ResponsiveButton>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	profile: state.profile.profile
});
export default connect(mapStateToProps, {
	createLicense
})(SplitForm);