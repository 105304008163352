import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { useEffect, useState } from "react";
import useStyles from "../../../login/styles";
import Swal from "sweetalert2";
//redux
import { connect } from "react-redux";
import { updateLicense } from "../../../../stores/licenses/actions";

const isValidDomain = require('is-valid-domain')
function SelectPlanDialog({
  selected,
  site,
  open,
  onClose = () => { },
  onSelect = () => { },
  updateLicense
}) {
  var classes = useStyles();
  const [website, setWebsite] = useState("")
  const [btnLoading, setBtnLoading] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault();
    try{
      if(!isValidDomain(website)){
        onClose()
        Swal.fire("Please enter a valid domain!", "Don't include http:// or https:// in the domain name", "warning")
        return;
      }
      setBtnLoading(true)
      await updateLicense({website}, site._id);
      onClose()
      Swal.fire("Website updated successfully!", "", "success")
    }catch(err){
      console.log(err)
      onClose()
      Swal.fire("Something went wrong!", "Please try again later", "error")
    }finally{
      setBtnLoading(false)
    }
  }
  return (
    <Dialog open={open}>
      <DialogTitle onClose={onClose}>Update Website Link</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <TextField
            id="website"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={website}
            onChange={e => setWebsite(e.target.value)}
            margin="normal"
            placeholder="Enter Domain/Subdomain"
            type="text"
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
          >Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"     
            disabled={btnLoading}       
          >Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  updateLicense,
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlanDialog);