import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import SiteTable from "./components/SiteTable/SiteTable";
import BillingTable from "./components/BillingTable";
import { formatCurrency, formatDate } from "../../helpers/formatters";
import mock from "./mock"
import { Edit, FindInPage } from "@material-ui/icons";
import moment from "moment";

//redux
import { connect } from "react-redux";
import { fetchLicenses } from "../../stores/licenses/actions";
import { fetchInvoices, fetchSubscriptions, fetchPlans } from "../../stores/payment/actions";

function Dashboard(props) {
  const {
    billing,
    user,
    plans,
    sites,
    bills
  } = mock
  const plan = plans[0]
  const classes = useStyles();
  const fetchSubscribedPlan = (subsription) => {
    const plan = props.plans.find((product) => product.plans[0].id === subsription.plan?.id)
    if (plan) {
      return plan
    }
  }
  useEffect(() => {
    props.fetchLicenses()
    props.fetchInvoices()
    props.fetchSubscriptions()
    props.fetchPlans()
  }, [])
  return (
    <>
      <PageTitle title={`Welcome, ${props.profile?.name}`} />
      <Typography variant="subtitle1" component="p" style={{ marginTop: -20, marginBottom: 30 }}>Submit feedback or report bugs: <a href="mailto:support@affiliate-fish.com">support@affiliate-fish.com</a></Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" component="p" style={{ marginBottom: 20 }}>Your Subscriptions</Typography>
          {props.subscriptions.map((subsription, i) => (
            <Card key={i}>
              <CardHeader
                title={fetchSubscribedPlan(subsription)?.name}
                action={
                  <Link to="/app/subscriptions" style={{ textDecoration: "none" }}>
                    <ResponsiveButton
                      variant="contained"
                      color="primary"
                      size="small"
                      icon={<Edit size="small" />}
                    >Manage</ResponsiveButton>
                  </Link>
                }
              />
              <CardContent>
                <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'baseline' }}>
                  <Typography variant="subtitle2" component="p">
                    Subscription ID: <span style={{ fontWeight: 'bold' }}>
                      {subsription.id}
                    </span>
                  </Typography>
                </div>
                <p>
                  <span className={classes.legendItemContainer}>
                    Next payment: {formatCurrency(subsription.plan.amount / 100)} on {moment.unix(subsription.current_period_end).format("MMMM Do YYYY")}
                  </span>
                </p>
                {/* <p>
                  <span className={classes.legendItemContainer}>
                    {billing.paymentMethod} {billing.paymentMethodNumber}
                  </span>
                </p> */}
              </CardContent>
            </Card>
          ))}
          {props.subscriptions.length === 0 && (
            <Typography variant="subtitle1" component="p" style={{ marginBottom: 20 }}>You don't have any active subscriptions!</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Your Sites"
              action={
                <Link to="/app/sites" style={{ textDecoration: "none" }}>
                  <ResponsiveButton
                    color="primary"
                    variant="contained"
                    icon={<Edit size="small" />}
                    size="small"
                  >Manage Sites</ResponsiveButton>
                </Link>}
            ></CardHeader>
            <CardContent>
              <p>
                You are using {props.licenses?.length} site{props.licenses?.length > 1 ? "s" : ""}.
              </p>
              <SiteTable sites={props.licenses} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Billing History"
            // action={
            //   <ResponsiveButton
            //     color="primary"
            //     variant="contained"
            //     icon={<FindInPage size="small" />}
            //     size="small"
            //   >See Full Report</ResponsiveButton>
            // }
            />
            <CardContent>
              <BillingTable bills={props.invoices} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => ({
  licenses: state.licenses.licenses,
  profile: state.profile.profile,
  invoices: state.payment.invoices,
  subscriptions: state.payment.subscriptions,
  plans: state.payment.plans,
});
const mapDispatchToProps = {
  fetchLicenses,
  fetchInvoices,
  fetchSubscriptions,
  fetchPlans
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);